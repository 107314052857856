.boxes {
    .boxes-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 -15px;
        @media( max-width: 850px ) {
            flex-direction: column;
            margin: 0;
        }
        .box {
            max-width: calc(33.3% - 30px);
            width: 100%;
            margin: 0 15px;
            border: 1px solid $border;
            border-radius: 10px;
            padding: 34px 39px 33px 32px;
            min-height: 389px;
            position: relative;
            background-color: #fafbfc;
            @media( max-width: 1200px ) {
                padding: 25px 30px 25px 28px;
                min-height: auto;
                max-width: calc(33.3% - 15px);
                margin: 0 7.5px;
            }
            @media( max-width: 1024px ) {
                padding: 25px 25px 15px 25px;
            }
            @media( max-width: 850px ) {
                margin: 0 0 25px 0;
                max-width: 100%;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .icon {
                position: absolute;
                top: 0;
                left: 30px;
                transform: translateY(-50%);
                min-width: 40px;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $red;
                border-radius: 50%;
                @media( max-width: 1200px ) {
                    min-width: 35px;
                    min-height: 35px;
                    p {
                        font-size: 20px !important;
                    }
                }
                @media( max-width: 1024px ) {
                    left: 25px;
                }
                p {
                    margin: 0;
                    color: $white;
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .title {
                h3 {
                    color: $blue;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0;
                    margin-bottom: 16px;
                    @media( max-width: 1200px ) {
                        font-size: 22px;
                    }
                }
            }
            .desc {
                * {
                    color: $gray;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    @media( max-width: 1024px ) {
                        font-size: 16px;
                    }
                }
                ul {
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        padding-left: 20px;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 19px;
                        @media( max-width: 1200px ) {
                            margin-bottom: 10px;
                        }
                        @media( max-width: 1024px ) {
                            padding-left: 14px;
                            margin-bottom: 5px;
                        }
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            transform: translateY(50%);
                            width: 8px;
                            height: 8px;
                            background-color: $red;
                            border-radius: 50%;
                            @media( max-width: 1024px ) {
                                width: 6px;
                                height: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}