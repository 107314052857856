.form {
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
    form {
        .group {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin: 23px 0;
            // padding-bottom: 20px;
            border-bottom: 1px solid $border;
            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0;
            }
            &:first-of-type {
                margin-top: 0;
            }
            .fields {
                // display: flex;
                // flex-wrap: wrap;
                // margin: 0 -15px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr; 
                grid-template-rows: 1fr; 
                grid-template-areas: 
                ". . .";
                gap: 30px 15px;
                max-width: 100%;
                margin-bottom: 20px;
                @media( max-width: 800px ) {
                    grid-template-columns: 1fr; 
                    grid-template-areas: ".";
                    gap: 20px 15px;
                }
                .row-item {
                    position: relative;
                    width: 100%;
                    .remove-item {
                        position: absolute;
                        top: calc(50% - 12px);
                        right: -25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        @media( max-width: 800px ) {
                            right: -17px;
                            top: calc(50% - 7.5px);
                            svg {
                                width: 15px!important;
                                height: 15px!important;
                            }
                        }
                        svg {
                            transform: rotate(45deg);
                            path {
                                fill: $red;
                            }
                        }
                    }
                    &.dates {
                        display: flex;
                        .input-date-picker-wrap {
                            &:first-of-type {
                                // margin-right: 15px;
                                .react-datepicker-input {
                                    border-right: none !important;
                                    border-radius: 10px 0 0 10px !important;
                                }
                            }
                            &:nth-of-type(2) {
                                // margin-left: 15px;
                                .react-datepicker-input {
                                    border-left: none !important;
                                    border-radius: 0 10px 10px 0 !important;
                                }
                            }
                            label {
                                position: absolute;
                                top: -19px;
                                @media(max-width: 800px ) {
                                    top: -15px;
                                }
                            }
                        }
                    }
                    &.date {
                       display: flex;
                       label {
                            position: absolute;
                            top: -19px;
                            @media(max-width: 800px ) {
                                top: -15px;
                            }
                        } 
                    }
                }
            }
            .finish {
                margin-bottom: 11px;
            }
            .row-item {
                &.hidden-item {
                    margin: 0;
                    visibility: hidden;
                }
                .btn-wrap {
                    // margin: 0 -15px;
                    margin-bottom: 20px;
                    .btn {
                        // margin: 0 15px;
                        max-width: 300px;
                        width: 100%;
                    }
                }
                &.submit {
                    .btn-wrap {
                        margin-top: 7px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}